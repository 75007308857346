.AboutMe-page {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    padding-top: 5vh;
    justify-content: center;
    align-items: center;
    flex-wrap: row;
    flex:0 1;
}

.AboutMe-img img {
    max-width: 100%;
    max-height: min(50vh, 500px);
    margin: auto;
    display: block;  

}
.AboutMe-img {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    max-height: min(50vh, 500px);
    padding: var(--m);
    border: 1rem solid;
    border-color: var(--red);
}


.AboutMe-text {
    margin: var(--l);
    margin-right: 5%;
    align-content: center;
    display: inline-flex;
    flex-direction: column;
    text-align: start;
    max-height: fit-content;
    flex: 0 1 30vw;

    background-color: var(--background);
}



.AboutMe-text h3 {

    height: 100%;
    font-size: 150%;
    font-family: var(--main-font);
    margin: var(--s);
}
.AboutMe-text p {
    font-size:80%;
    height: 100%;
    font-family: var(--second-font);
    margin: var(--s);
}

@media screen and (max-width: 600px){
    .AboutMe-page {
        flex-direction: column;
        width:100%; 
        justify-content: flex-end;
}
    .AboutMe-text {
        flex: 0 1 100vw;
        justify-content: center;
        margin: var(--m);
    }
    .AboutMe-text h3 {
        background-color: rgba(46, 196, 182, 0.3);
        padding: var(--s);
        height:fit-content;
    }
}
/* .u-textWrapper {
    width: 80%;
} */

@media screen and (max-width: 880px){
    .AboutMe-text {padding-bottom: 70px ;}
}
