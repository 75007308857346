.home {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
}
.Home-page{
    display: inline-flex;
    height: 90%;
    align-items: center;
    justify-content: center;
}
.navbar-wrapper {
    display: inline-flexbox;
}
.Home-text {
    border-radius: 25px;
    background-color: rgba(255, 252, 239, 0.8);
    display: inline-flexbox;
    text-align: start;
    font-family: var(--main-font);
    width:50%;
}

.Home-text h3 {
    font-size: 8vh;
    color: var(--black);
    width: 100%;
    margin: var(--m);
}

.Home-text h5 {
    font-size: 5vh;
    color: var(--grey);
    width: 100%;
    margin: var(--m);
}


@media screen and (max-width: 600px){
    .Home-page {
        flex-direction: column;
        height: calc(100% + 25vh);
        width:100%; 
        justify-content: flex-start;
}
    .Home-text {
        flex-direction: column;
        width: 100%;
        flex: 0 1;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .Home-text h5 {
        font-size:3vh;
        padding: var(--s);
        width: 80%;
        margin:1%;
    }
    .Home-text h3 {
        font-size: 5vh;
        padding: var(--s);
        width:80%;
        margin:1%;
    }
}


@media screen and (max-width: 880px){
    .Home-text {padding-bottom: 70px ;}
}


@media screen and (min-width: 600px){
    .home {
        background: url("../../public/front-page-border.png");
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: contain;
    }
}
@media screen and (min-width: 900px){
    .home {
    background: url("../../public/front-page-border.png"), url("../../public/front-page-border-right.png");
    background-repeat: no-repeat;
    background-position: left bottom, right bottom;
    background-size: contain;
    }
}