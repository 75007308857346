.NavBar-container {
    padding-left: 100px;
    display: flex;
    border-bottom: var(--black) solid 0.7px;
    justify-content: flex-start;
    max-height: 60px;
    height: auto;
    background-color: rgb(255, 252, 239, 0.8);
    position: sticky;
    top: 0px;
}

@media screen and (max-width: 600px){
    .NavBar-container {
        justify-content: center;
        align-items: center;
        padding: 0;
        max-height:fit-content;
    }
    .els {
        display: flex;
        flex: 0 1 90%;
        flex-direction: column;
        align-items: center;
        column-gap: 4px;
    };
}

.els img {
    max-height:90%;
    max-width:100%;
    padding: var(--xs);
    border-left: solid 3px;
    border-right: solid 3px;
    border-top: solid 0.5px;
    border-bottom: solid 0.5px;
}

.els {
    display: flex;
    justify-content: flex-start;
    flex: 0 1 50vw;
    gap: 20%;
    margin:0;
    padding: var(--s);
}

.nav-button {
    display: flex;
    height: 100%;
    font-family: var(--main-font);
    align-items: center;
}

ul {
    padding-inline-start: 0;
}

a {
    text-decoration: none;
}