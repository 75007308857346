@import url('https://fonts.googleapis.com/css2?family=Baumans&family=Staatliches&display=swap');

:root {
    --background: #FFFCEF;
    --red: #E4572E;
    --blue: #2EC4B6;
    --black: #362C28;
    --grey: #637081;

    --xs: 4px;
    --s: 8px;
    --m: 16px;
    --l: 24px;

    --main-font: 'Staatliches', cursive;
    --second-font: 'Baumans', cursive;
    
}


.navbar-wrapper {
    width: 100%;
}

.u-link {
    color: var(--black);
}

.u-link:hover {
    font-weight: 600px;
    color: var(--grey);
    text-decoration-style: underline;
    /* text-decoration-color: var(--grey); */
    opacity:0.8;
    cursor: url('/public/select.png'), pointer;
}

.u-pageContainer {
    position:absolute;    
    width: 100%;
    height: calc(100%);
    cursor: url('/public/pointer.png'), default;
}
.u-textWrapper {
    display: inline-block;
    max-height: fit-content;
}

.u-cursorText {
    cursor: url('/public/blinker.png'), text;
}

.u-pointer {
    cursor: url('/public/select.png'), pointer;
}

.u-inlineBlock {
    display: inline-block;
    text-align: center;
}