.Footer-container {
    display: flex;
    position: fixed; 
    bottom:10px;
    max-height: 40px;
    width: 100%;
    justify-content: center;
    border-top: solid var(--black) 2px;
    border-bottom: solid var(--black) 2px;
    align-items: center;
    background-color: rgb(255, 252, 239, 0.8);
}

.footer-els {
    display: inline-flex;
    justify-content: center;
    gap: 10%;
}
 
.imgResize {
    align-self: center;
    padding: 5px;
    }
    
.imgResize img {
      width: 90%;
      height: auto; 
    }
 
.Image {
    max-width: 20px;
    cursor: pointer;
    margin: 5px;
}

.Image:hover {
    opacity: 0.6;
    transition: opacity 0.55s ease-in-out;
    -moz-transition: opacity 0.55s ease-in-out;
    -webkit-transition: opacity 0.55s ease-in-out;
}